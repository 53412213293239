import React from "react";

/** Components */
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import Content from "../components/Content";
import AuthorHeader from "../components/AuthorHeader";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <Header>
        <AuthorHeader />
      </Header>
      <Content hasHeader>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Content>
    </Wrapper>
  </Layout>
);
export default NotFoundPage;
